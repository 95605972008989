<script>

export default {

  name: "GoogleMaps",

props : ["location"],
  data: () => ({
    locations : {
        Hafen : {
            position : {
            id : "Hafen",
            lat : 52.29563,
            lng : 8.02796,},
            name : "Hafen",
            adress : "Am Speicher 4, 49090 Osnabrück"
        },
    },
   
  })
};
</script>

<template>
    
  <div>

  <GMapMap
      :center="{lat: 52.29563, lng:8.02796}"
      :zoom="12"
      map-type-id="terrain"
      style="width: 100%; height: 300px"
  >
  <GMapMarker
      :key="index"
      v-for="(l, index) in locations"
      :position="l.position"
      :clickable="true"
    >
    <GMapInfoWindow :opened="l.name == location">
        <div>
            <strong>{{ l.name }}</strong> <br>
            {{l.adress}}
        </div>
      
      </GMapInfoWindow>
  </GMapMarker>


  </GMapMap>


  </div>
</template>
