<template>
    <v-container>
      <v-card color="#ffe4b9">
        <v-card-title>Login</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="email"
            label="E-Mail"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Passwort"
            type="password"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="login">Einloggen</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import { signInWithEmailAndPassword } from "firebase/auth";
  import { auth } from "@/firebase/index.js";
  
  export default {
    data() {
      return {
        email: '',
        password: '',
      };
    },
    methods: {
      async login() {
        try {
          await signInWithEmailAndPassword(auth, this.email, this.password);
          this.$router.push('/intern');
        } catch (error) {
          console.error('Fehler beim Einloggen:', error);
          // Hier können Sie einen Fehlerdialog oder eine Benachrichtigung anzeigen
        }
      }
    }
  };
  </script>