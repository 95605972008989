import { defineStore } from 'pinia'
import {ref, reactive} from 'vue'
export const useUserStore = defineStore('user', {
  state: () => ({ 
    order: reactive({}),
    qr_scanned: ref(false),
    firebase_doc_id: ref(''),
   }),
  getters: {

  },
  actions: {

  },
  persist: { storage: window.localStorage },
})
// export default useUserStore
