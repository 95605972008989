<script setup>

</script>
<script>
</script>
<template>
  <div class="mx-5 my-5">
    <h2>Datenschutzhinweis</h2>
    <p>Sehr geehrte Nutzerinnen und Nutzer,</p>
    <p>Wir möchten Sie darüber informieren, dass im Rahmen unserer Dienste Ihre personenbezogenen Daten - speziell Ihr Name und Ihre E-Mail-Adresse - verarbeitet und gespeichert werden. Diese Datenverarbeitung erfolgt zum Zweck der Bereitstellung und Optimierung unserer Dienstleistungen für Sie.</p>
    <p>In diesem Prozess werden keine personenbezogenen Daten erhoben. Diese stammen aus Ihrer Bestellung auf <a href="https://campusbier.de/">campusbier.de</a>. Die Datenschutzerklärung finden Sie <a href="https://campusbier.de/policies/privacy-policy">hier</a> </p>
    <h3>Systeme der Datenverarbeitung:</h3>
    <ul class="mx-5">
        <li><strong><a href="https://firebase.google.com/support/privacy?hl=de">Google Firebase</a>:</strong> Wir nutzen Google Firebase zur Speicherung und Verwaltung Ihrer Daten. Google Firebase ist ein von Google LLC bereitgestellter Dienst, der die Datensicherheit gemäß den Standards der Datenschutz-Grundverordnung (DSGVO) gewährleistet.</li>
        <li><strong><a href="https://www.mailersend.com/legal/privacy-policy">Mailersend</a>:</strong> Ebenso verwenden wir Mailersend für den E-Mail-Verkehr. Mailersend ist ein Dienst, der die sichere und DSGVO-konforme Verarbeitung Ihrer E-Mail-Daten ermöglicht.</li>
    </ul>
    <h3>Datenschutz und Ihre Rechte:</h3>
    <p>Ihre Daten werden streng vertraulich behandelt und nicht an Dritte weitergegeben, es sei denn, dies ist für die Erbringung unserer Dienstleistungen notwendig oder gesetzlich vorgeschrieben. Sie haben das Recht, Auskunft über Ihre bei uns gespeicherten Daten zu erhalten, diese zu berichtigen, zu löschen oder die Verarbeitung einzuschränken. Zudem steht Ihnen ein Widerspruchsrecht gegen die Verarbeitung Ihrer Daten zu.</p>
    <h3>Kontaktperson für Datenschutzanfragen:</h3>
    <p>Bei Fragen zum Datenschutz oder zur Ausübung Ihrer Rechte wenden Sie sich bitte an unseren Mitarbeiter:</p>
    <p>Philipp Zmijewski<br>
    E-Mail: <a href="mailto:philipp.zmijewski@hs-osnabrueck.de">philipp.zmijewski@hs-osnabrueck.de</a></p>
    <p>Wir danken Ihnen für Ihr Vertrauen und sind bestrebt, Ihre Daten mit höchster Sorgfalt und Sicherheit zu behandeln.</p>
    <p>Mit freundlichen Grüßen,<br>
    Ihr Campusbier Team </p>
  </div>
</template>