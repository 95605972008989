<script setup>

import axios from "axios";
import qs from 'qs';
import AddProductHub from "../components/AddProductHub.vue";
</script>
<script >
export default {
  data() {
    return {
      locations: ["haste-hub-1", "hafen-hub-1"],
      selectedHub: null,
      LagerbestandGeändert: false,
      showAddProductDialog: false,
      products: {
        "westerberg-hub-1": [
          {
            name: "Kaffee",
            sku: "12345",
            box: "westerberg-1",
            Lagerbestand: 1,
          },
          {
            name: "Geschenkbox",
            sku: "54321",
            box: "westerberg-2",
            Lagerbestand: 2,
          },
        ],
        "haste-hub-1": [
          {
            name: "Müsli",
            sku: "89762",
            box: "haste-1",
            Lagerbestand: 1,
          },
          {
            name: "Geschenkbox",
            sku: "54321",
            box: "haste-2",
            Lagerbestand: 2,
          },
        ],
      },
    };
  },
  computed: {
    originalProducts() {
      return this.products;
    },
    tableHeaders() {
      return [
        { text: "Produkt", value: "name" },
        { text: "SKU", value: "sku" },
        { text: "Box", value: "box", width: "150px" },
        { text: "Lagerbestand", value: "Lagerbestand" },
        { text: "Reserviert", value: "Reserviert" },
        { text: "Verfügbar", value: "Verfügbar" },
      ];
    },
  },
  components: {},
  async mounted() {
    var hubs = await this.getHubData();
    var { products, locations } = this.getProductAndLocationsFromHubData(hubs);

    this.products = products;
    this.locations = locations;
  },
  // watch: {
  //   selectedLocation(newLocation) {
  //     if (newLocation && this.products[newLocation]) {
  //       this.products[newLocation].forEach(product => {
  //         product.hasChanged = false;
  //       });
  //       // Speichere eine Kopie der ursprünglichen Produkte, um Änderungen zu erkennen
  //       this.originalProducts[newLocation] = JSON.parse(JSON.stringify(this.products[newLocation]));
  //     }
  //   },
  // },
  methods: {
    async getHubData() {
      let url;
      if (process.env.NODE_ENV === "dev") {
        url = "http://127.0.0.1:5001/campusfoods/us-central1/getHubData";
      } else {
        url = "https://gethubdata-ujczn5iila-uc.a.run.app";
      }

      try {
        const response = await axios.get(url);
        return response.data; // Die Daten der Antwort
      } catch (error) {
        console.error("Fehler beim Abrufen der Hub-Daten:", error);
        throw error; // Fehler weitergeben oder entsprechend behandeln
      }
    },
    getProductAndLocationsFromHubData(hubs) {

      let products = {};
      let locations = [];
      hubs.forEach((hub) => {
        const hubName = hub.name;
        locations.push(hubName);
        let productsArray = [];

        // for (var product in hub.products) 
        hub.products.forEach(product =>{

          // const product = hub.products[productName];
          productsArray.push({
            name: product.name,
            sku: product.sku,
            box: product.box,
            Lagerbestand: product.Lagerbestand, 
            Reserviert: product.Reserviert,
            Verfügbar: product.Lagerbestand - product.Reserviert,
            geändert: false,
          });
        })

        products[hubName] = productsArray;
      });

      return { products, locations };
    },
    checkAndSubmitChanges() {
      const currentProducts = this.products[this.selectedHub];
      // const originalProducts = this.originalProducts[this.selectedLocation];

      currentProducts.forEach((product, index) => {

        if (product.geändert) {
          // Produkt hat sich geändert, rufe updateProductStock auf
          this.updateProductStock(
            this.selectedHub,
            product.name,
            product.Lagerbestand
          );
        }
      });
    },
    async updateProductStock(hubName, productName, newStock) {
      let url;
      if (process.env.NODE_ENV === "dev") {
        url =
          "http://127.0.0.1:5001/campusfoods/us-central1/updateProductStockAtHub";
      } else {
        url = "https://updateproductstockathub-ujczn5iila-uc.a.run.app";
      }

      let data = {
          hubName,
          productName,
          newStock,
        }
      data = qs.parse(data)
      try {
        await axios.post(url, data);
        console.log("Lagerbestand aktualisiert für", productName);
        this.LagerbestandGeändert = true;
      } catch (error) {
        console.error("Fehler beim Aktualisieren des Lagerbestands:", error);
      }
    },
    handleProductAdded(success) {
      if (success) {
        this.showAddProductDialog = false;
        // this.LagerbestandGeändert = true;
      }
    },
  },
};
</script>
<template>
  <div>
    <h1 class="text-center">Lagerbestand Boxen</h1>
    <v-raw>
      <v-col>
        <v-select
          label="Standort"
          :items="locations"
          v-model="selectedHub"
        ></v-select>
      </v-col>
    </v-raw>
    <v-raw>
      <v-col v-if="selectedHub != null">
        <v-data-table :items="products[selectedHub]">
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.Lagerbestand="{ item }">
            <v-text-field
              type="number"
              v-model="item.Lagerbestand"
              min="0"
              @change="item.geändert = true"
            ></v-text-field>
          </template>
          <template v-slot:item.geändert="{ item }">
    <span>{{ item.geändert ? 'Ja' : 'Nein' }}</span>
  </template>

        </v-data-table>
      </v-col>
    </v-raw>
    <v-raw>
      <v-col>
        <v-snackbar v-model="LagerbestandGeändert" timeout="3000"
          >Lagerbestand erfolgreich geändert</v-snackbar
        >
        <v-btn
          class="cf-btn-main"
          block
          v-show="selectedHub != null"
          @click="checkAndSubmitChanges"
        >
          <v-icon>mdi-check</v-icon>
          Lagerbestand anpassen
        </v-btn>
      </v-col>
    </v-raw>
    <v-raw>
      <v-col>
        <v-btn
          v-show="selectedHub != null"
          class="cf-btn-main"
          block
          @click="showAddProductDialog = true"
        >
          <v-icon>mdi-plus</v-icon> Produkt hinzufügen
        </v-btn>
      </v-col>
    </v-raw>
    <v-dialog v-model="showAddProductDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Produkt hinzufügen
          <v-spacer></v-spacer>
          <v-btn icon @click="showAddProductDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <AddProductHub @product-added="handleProductAdded" :hub="selectedHub"></AddProductHub>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>