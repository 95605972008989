

<script setup>
import HomeInputField from "../components/HomeInputField.vue"
import { useRoute } from 'vue-router'
</script>
<script >
export default {
  data() {
    return {
      toggle: true,
      InputVariant : 0
    }
  },
  components: {HomeInputField},
  mounted() {
    this.getURLParams()
  },
  methods: {
    setInputVariant(value){
      this.InputVariant = value
      

    },
    getURLParams: function(){    // "/home/:id/:zip/:pin",
    const route = useRoute()
    const params = route.query
    this.params = params
    if(params.id && params.zip){
      this.InputVariant = 1
    }
    else if(params.id && params.pin){
      this.InputVariant = 2
    }
    else{
      this.InputVariant = 0
    }


  }

  }
}
</script>
<template>
  <div class="d-flex flex-column ">

    <div class="text-center" v-if="InputVariant == 0">
      <p class="mb-6 text-center">
        <b><i>Pickup from your local hub!</i></b>
        </p>
      <v-row class="text-center">
      
        <v-divider class="mb-4"></v-divider>
        <!-- <v-col>
          <p class="mb-4">Du hast bei Campusbier bestellt und möchtest ein <b>Abholdatum auswählen</b> ?</p>
      


        </v-col>
        <v-divider vertical="true"></v-divider> -->
        <v-col>
          <p class="mb-4" >Du hast bereits eine <b>PIN</b> erhalten und möchtest deine <b>Produkte abholen</b> ?</p>

      
        </v-col>
      </v-row>
      
      <v-row class="text-center ">
        <!-- <v-col>
          <v-btn 
          
        @click="setInputVariant(1)" 
        class="cf-btn-main">
        Auswählen
      </v-btn>
        </v-col>
        <v-divider vertical="true"></v-divider> -->
        <v-col>
          <v-btn
      @click="setInputVariant(2)"
        class="cf-btn-main"
        size="x-large">
        <v-icon>
          mdi-arrow-up-bold-circle-outline
        </v-icon>
        Abholen
      </v-btn>
        </v-col>
        <v-divider class="mt-4"></v-divider>
      </v-row>


    </div>
   
    <div class="text-center mt-8" v-if="InputVariant == 0">
      <small>Die Website ist noch in der Entwicklung. Bei Fragen oder Anregungen wende dich bitte an uns <a href="mailto:wir@campusfoods.de" style="color:black">via Mail</a>.</small>
    
    </div>
    <div class="text-center mt-4" v-if="!InputVariant == 0">
      <HomeInputField :InputVariant="InputVariant" :params="params" :key="InputVariant"/>
    </div>
    <v-btn
    v-if="!InputVariant == 0"
    @click="setInputVariant(0)"
    variant="outlined"
    max-width="100px"
          elevation="4"
          class="mt-6"
        rounded="pill"
        color="#0e2f16"
        filled="black">
  <v-icon>mdi-arrow-left</v-icon> zurück
  </v-btn>
  </div>

</template>
<style>

</style>