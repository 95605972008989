<script setup>
import axios from "axios";
</script>
 
<script>
export default {
  props: {
    order: Object,
  },
  data() {
    return {
      dialog: true,
      availableProducts: [],
      availableLocations: [],
      selectedProduct: null, // Für das ausgewählte Produkt
      expansions: null,
    };
  },
  computed: {
    availableQuantity() {
      return this.availableProducts.find(
        (product) => product.name === this.selectedProduct
      ).Verfügbar;
    },
  },
  async mounted() {
    try {
      const hubs = await this.getHubData();
      const products = this.getProductAndLocationsFromHubData(hubs);
      this.availableProducts = products;
    } catch (error) {
      console.error("Fehler beim Laden der Hub-Daten:", error);
    }
  },

  methods: {
    close() {
      this.dialog = false;
    this.$emit("close");
  },
    async getHubData() {
      const url = "http://127.0.0.1:5001/campusfoods/us-central1/getHubData";

      try {
        const response = await axios.get(url);

        return response.data; // Die Daten der Antwort
      } catch (error) {
        console.error("Fehler beim Abrufen der Hub-Daten:", error);
        throw error; // Fehler weitergeben oder entsprechend behandeln
      }
    },
    async updateOrder() {
      let url;
if (process.env.NODE_ENV === 'dev') {
	url =  "http://127.0.0.1:5001/campusfoods/us-central1/updateOrderAndHubReservations";
	}
else {
	url = "https://updateorderandhubreservations-ujczn5iila-uc.a.run.app" 
	}
 
       
      const data = {
        orderId: this.order.id,
        updatedOrderData: this.order,
        hubName: this.order.pickup_location,
      };
      const response = await axios.post(url, data);
      if (response.status === 200) {
        this.close();
      } else {
        // Behandlung anderer Statuscodes
        console.error(`Update fehlgeschlagen: Statuscode ${response.status}`);
      }
      this.$emit("update-order", this.order);
    },
    close() {
      this.$emit("close");
    },
    addProductToOrder() {
      if (
        !this.selectedProduct ||
        !this.selectedLocation ||
        !this.selectedProductQuantity
      ) {
        console.error(
          "Bitte wählen Sie ein Produkt und geben Sie eine Menge an."
        );
        return;
      }
      // Produkt zur Bestellung hinzufügen
      const newProduct = {
        name: this.selectedProduct,
        // Weitere benötigte Felder hier einfügen
        quantity: this.selectedProductQuantity,
      };
      this.order.items.push(newProduct);
      // Zurücksetzen der Auswahl
      this.selectedProduct = null;
      this.selectedProductQuantity = 0;
    },
    getProductAndLocationsFromHubData(hubs) {
      let products = [];
      let currentHubName = this.order.pickup_location; // Der Name des Hubs aus der aktuellen Bestellung

      // Finde den Hub, der dem aktuellen Hub-Namen entspricht
      const currentHub = hubs.find((hub) => hub.name === currentHubName);

      if (currentHub) {
        for (const productName in currentHub.products) {
          const product = currentHub.products[productName];
          products.push({
            name: productName,
            sku: product.sku, // Angenommen, dass jedes Produkt ein SKU-Feld hat
            box: product.box, // Angenommen, dass jedes Produkt ein Box-Feld hat
            Lagerbestand: product.Lagerbestand,
            Reserviert: product.Reserviert,
            Verfügbar: product.Lagerbestand - product.Reserviert,
            Menge: 0,
          });
        }
      }
      return products;
    },
  },
};
</script>
  
<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
    <v-card>
      <v-card-title>Bestelldetails - {{ order.id }}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="order.first_name"
                label="Vorname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="order.last_name"
                label="Nachname"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="order.mail" label="E-Mail"></v-text-field>
            </v-col>
          </v-row>
          <!-- Weitere Felder hier hinzufügen -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="order.pickup_location"
                label="Abholort"
                readonly="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Items bearbeiten -->
          <div class="text-center">
            <v-subheader size="large">Artikel in Boxen</v-subheader>
          </div>

          <div v-for="(box, boxIndex) in order.boxes" :key="boxIndex">
            <v-row>
              <v-col cols="12">
                <v-subheader
                  ><b
                    >Box {{ box.boxName }} ({{ box.boxNumber }})</b
                  ></v-subheader
                >
              </v-col>
            </v-row>

            <v-row v-for="(item, itemIndex) in box.items" :key="itemIndex">
              <v-col cols="6">
                <v-text-field
                  v-model="item.name"
                  label="Artikelname"
                  readonly="true"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.quantity"
                  label="Menge"
                  type="number"
                ></v-text-field>
              </v-col>
              <!-- Weitere Felder für jeden Artikel -->
            </v-row>
          </div>

          <v-divider thickness="5"></v-divider>

          <div class="text-center">
            <v-subheader class="mt-5">Artikel hinzufügen</v-subheader>
          </div>

          <v-row v-if="availableProducts">
            <v-col cols="12">
              <v-select
                :items="availableProducts.map((product) => product.name)"
                item-text="name"
                item-value="name"
                label="Produkt"
                v-model="selectedProduct"
              ></v-select>
            </v-col>

            <v-col cols="12" v-if="selectedProduct">
              <p>
                Verfügbar von {{ selectedProduct }} : {{ availableQuantity }}
              </p>
              <v-text-field
                label="Menge"
                type="number"
                :max="availableQuantity"
                v-model="selectedProductQuantity"
              ></v-text-field>
            </v-col>

            <v-col class="text-center" cols="12">
              <v-btn @click="addProductToOrder">Produkt hinzufügen</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="updateOrder">Speichern</v-btn>
        <v-btn color="secondary" @click="close">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
 