import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { createPinia } from 'pinia'
import { createPersistPlugin } from 'pinia-persist';
import VueGoogleMaps from '@fawmi/vue-google-maps'
// import Footer from "./components/Footer.vue"
loadFonts();
const googleMapsAuth = require("../googleMapsApi.json")

const app = createApp(App)
const pinia = createPinia()
pinia.use(createPersistPlugin())

app.use(pinia)


app.use(router)
app.use(vuetify)
app.use(VueGoogleMaps, {
    load: googleMapsAuth,
})
app.mount("#app");
// app.component("Footer", Footer)
