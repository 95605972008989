<script setup>
import router from "@/router/index.js"
</script>

<script>
export default {
  name: "Header",

  data: () => ({}),
};
</script>

<template>
  <!-- <v-bottom-navigation>
      <v-btn value="recent">
        <v-icon>mdi-history</v-icon>

        Recent
      </v-btn>

      <v-btn value="favorites">
        <v-icon>mdi-heart</v-icon>

        Favorites
      </v-btn>

      <v-btn value="nearby">
        <v-icon>mdi-map-marker</v-icon>

        Nearby
      </v-btn>
      

    </v-bottom-navigation> -->

  <div class="text-center">
    <v-img :src="require('../assets/logo_cf.svg')" contain height="100" @click="router.push('/')"/>
  </div>
</template>
