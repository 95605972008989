<script>
import {db} from "@/firebase/index.js";
import {useUserStore} from "@/store/index.js";
import {getDoc, doc} from 'firebase/firestore';
import { collection, addDoc } from "firebase/firestore"; 
import router from "@/router/index.js"
export default {
  props: ["InputVariant", "params"],
  data: () => ({
    userName: "",
    orderId: "",
    zip: "",
    pin: "",
    order_alert: false,
    auth_alert: false,
    auth_option: null,
    // rules: [(value) => vm.checkCustomerInFirebase(value)],
    timeout: null,
  }),
  mounted () {
    this.setURLParams()
  },
  methods: {
    setURLParams(){
      if(Object.keys(this.params).length == 0){
        return
      }
      if(this.InputVariant == 1){
        this.orderId = this.params.id
        this.zip = this.params.zip
        this.validate()
      }
      else if(this.InputVariant == 2){
        this.orderId = this.params.id
        this.pin = this.params.pin
        this.validate()
      }
    },
    async getUser() {
      
      var userStore = useUserStore();
      // this.orderId = "41005365322" // DEV ONLY
      const docSnap = await getDoc(doc(db, "Orders", this.orderId));

      if (docSnap.exists()) {
       
        userStore.order = docSnap.data();
        userStore.order.order_id = this.orderId;
        
        return true;
      } else {
     
        return false;
      }
    },

    async checkCustomerInFirebase() {
      const userStore = useUserStore();
      var is_valid = false;
      // this.zip = 49074 // DEV ONLY
      // this.pin = 6869 // DEV ONLY
      if (this.InputVariant == 1) {
        this.auth_option = "Postleitzahl";
        
        if (userStore.order.zip == this.zip) {
          is_valid = true;
        }
      } else if (this.InputVariant == 2) {
        this.auth_option = "PIN";
        if (userStore.order.pin == this.pin) {
          is_valid = true;
        }
        // FOR DEV ONLY
        else{
          is_valid = true;
        }
      }

      return is_valid;
    },
    async validate(){
    let res = await this.getUser();
    if (!res) {
      this.order_alert = true;
      return;
    }
    let valid = await this.checkCustomerInFirebase();
    if (!valid) {
      this.auth_alert = true;
      setTimeout(() => {
        this.auth_alert = false;
      }, 5000);
      return;
    }
    if(this.InputVariant == 1){

    router.push("/select")
    }
    else if(this.InputVariant == 2){

      router.push("/verify")}

  },
  },

};
</script>


<template>
  <div>
    <v-card style="background-color: #FAFAD2;" >
      <v-card-item>
        <v-card-title>Abholdatum auswählen</v-card-title>
      </v-card-item>
      <v-card-text v-if="InputVariant == 1"
        >Gebe deine Bestellnummer und deine Postleitzahl an, um ein Datum für
        deine Abholung in den Abholboxen auszuwählen</v-card-text
      >
      <v-card-text v-if="InputVariant == 2"
        >Gebe deine Bestellnummer und deine PIN aus der Mail an, um deine
        Produkte abzuholen</v-card-text
      >

      <v-form>
        <v-text-field class="mx-2" v-model="orderId" label="Bestellnummer" ></v-text-field>

        <v-text-field
          v-model="zip"
          class="mx-2"
          v-if="InputVariant == 1"
          label="Postleitzahl"
          clearable
        ></v-text-field>
        <v-text-field
          v-model="pin"
          class="mx-2"
          v-if="InputVariant == 2"
          label="PIN"
          clearable
        ></v-text-field>
        <!-- TODO: ADD RULES -->
        <v-alert
          v-show="order_alert"
          type="error"
          title="Bestellung nicht bekannt"
          text="Die Bestellung konnte im System nicht gefunden werden."
        ></v-alert>
        <v-alert
          v-show="auth_alert"
          type="error"
          title="Authentifizierung nicht möglich"
          text="Bestellnummer und PLZ/PIN stimmen nicht überein"
          class="mx-4 mb-4"
        ></v-alert>
        <v-btn @click="validate" class="mb-4" size="large"    rounded="pill"
        variant="outlined"
        color="#0e2f16"
        
        style="background-color: #DCEDC8;"
   >Los gehts</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
</script>