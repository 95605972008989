<script setup>
import { db, auth } from "@/firebase/index.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, setDoc, addDoc, doc } from "firebase/firestore";
</script>

<script>
export default {
  name: "AdminView",
  data: () => ({
    // mail: "philipp.zmijewski@hs-osnabrueck.de",
    // pwd: "test123",
    mail: "",
    pwd: "",
    success_auth: false,
    user: null,
    all_boxes: [],
    dialog: false,
    order_dialog: false,
    show_order_alert: false,
  }),
  methods: {
    async authenticate() {
      signInWithEmailAndPassword(auth, this.mail, this.pwd)
        .then((userCredential) => {
          // Signed in
          getDocs(collection(db, "Boxes")).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              this.all_boxes.push(data);
            });
          });

          this.user = userCredential.user;
          this.success_auth = true;
        })
        .catch((error) => {
          console.log(error.code);
          console.log(error.message);
        });
    },
    async open_box(box) {
      let docRef = await addDoc(collection(db, "requests"), {
      "mac_address": box.lock_mac,
      "user_code" : box.lock_user_code,
      "preparation_request": false,
      "unlock_trigger" : true,
      "timestamp" : new Date().toISOString(),
      "note" : "Admin unlock by " + this.mail,
  });
      setTimeout(() => {
        this.dialog = false;
      }, 3000);
    },
    async createDummyOrder() {
      this.order_dialog = false;
      var items = [];
      var random_order_id = Math.floor(Math.random() * 1000000);
      random_order_id = "DUMMY-" + random_order_id.toString();
      for (let i = 0; i < 3; i++) {
        items.push({
          name: "Dummy Artikel " + i.toString(),
          sku: "99999",
          size: 1,
          quantity: 2,
          productIdShopify: "999999",
        });
      }
      // Create a JSON object
      var order = {
        first_name: "Max",
        last_name: "Mustermann",
        created_at: new Date().toISOString(),
        name: random_order_id,
        id: random_order_id,
        mail: this.mail,
        items: items,
        orderIdShopify: random_order_id,
        zip: 49090,
        order_status_url: "https://www.google.de",
        status: "open",
      };
      order.boxes_required = 1;
      await setDoc(doc(collection(db, "Orders"), random_order_id), order);
      this.random_order_id = random_order_id;
      this.show_order_alert = true;

    },
  },
};
</script>

<template>
  <div>
    <h2 class="text-center mb-5">Admin</h2>
    <div v-if="!success_auth" class="text-center">
      <v-text-field
        v-model="mail"
        label="E-mail"
        required
        clearable
      ></v-text-field>
      <v-text-field
        v-model="pwd"
        label="Password"
        type="password"
        required
        clearable
      >
      </v-text-field>
      <v-btn @click="authenticate">Login</v-btn>
    </div>
    <div v-else>
      <h3 class="text-center mb-5">Dummy Bestellung</h3>
      <div class="text-center">
        <v-btn
          class="mb-5"
          color="0e2f16"
          dark
          large
          depressed
          outlined
          width="auto"
        >
          Bestellung erstellen
          <v-dialog v-model="order_dialog" activator="parent" width="auto">
            <v-card>
              <v-card-text>
                Sicher, dass du eine Bestellung unter {{ this.mail }} erstellen
                möchtest?
              </v-card-text>
              <v-card-actions>
                <v-btn color="0e2f16" block @click="createDummyOrder"
                  >Ja, möchte ich</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-btn>
        <p v-show="show_order_alert">
        Die Bestellung mit der ID <b>{{ random_order_id }}</b> wurde erstellt.
        Die PLZ lautet 49090
        </p>
      </div>
      <h3 class="text-center mb-5">Alle Boxen</h3>
      <v-list
        density="compact"
        class="justify-center"
        style="background-color: beige"
        active-color="#FFFFF"
      >
        <v-list-item v-for="box in all_boxes" :key="box.id" class="mb-1">
          <v-dialog v-model="dialog" activator="parent" width="auto">
            <v-card>
              <v-card-text> Möchtest du Box {{ box.id }} öffnen? </v-card-text>
              <v-card-actions>
                <v-btn color="green" block @click="open_box(box)">Öffnen</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template v-slot:prepend>
            <v-icon icon="mdi-lock"></v-icon> 
          </template>
          <v-list-item-title>
            {{ box.id }}
          </v-list-item-title>
          <v-list-item-subtitle>
            MAC: {{ box.lock_mac }} status: {{ box.status }}
          </v-list-item-subtitle>
          <v-divider class="mt-2"></v-divider>
        </v-list-item>

      </v-list>
    </div>
  </div>
</template>