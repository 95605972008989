<script setup>
import { useRoute } from "vue-router";
import axios from "axios";
import qs from "qs";
console.log(process.env.NODE_ENV)
</script>
<script >
export default {
  data() {
    return {
      locations: [],
      selectedLocation: null,
      firstname: null,
      lastname: null,
      email: null,
      orderId: null,
      pin: null,
      url: null,
      pickupdate: null,
      OrderSubmitted: false,
      snackbar: false,
      dialogConfirm: false,
      products: {
        "westerberg-hub-1": [
          {
            name: "Kaffee",
            sku: "12345",
            box: "westerberg-1",
            Lagerbestand: 1,
            Menge: 0,
          },
          {
            name: "Geschenkbox",
            sku: "54321",
            box: "westerberg-2",
            Lagerbestand: 2,
            Reserviert: 0,
            Menge: 0,
          },
        ],
        "haste-hub-1": [
          {
            name: "Müsli",
            sku: "89762",
            box: "haste-1",
            Lagerbestand: 1,
            Reserviert: 0,
            Menge: 0,
          },
          {
            name: "Geschenkbox",
            sku: "54321",
            box: "haste-2",
            Lagerbestand: 2,
            Reserviert: 0,
            Menge: 0,
          },
        ],
      },
    };
  },
  components: {},
  async mounted() {
    var hubs = await this.getHubData();
    var { products, locations } = this.getProductAndLocationsFromHubData(hubs);

    this.products = products;
    this.locations = locations;
  },
  computed: {
    textToCopy() {
      return `BESTELLNUMMER: ${this.orderId}\nPIN: ${this.pin}\nURL: ${this.url}\n`;
    },
  },
  methods: {
    copyText() {
      navigator.clipboard
        .writeText(this.textToCopy)
        .then(() => {
          // Hier können Sie eine Benachrichtigung oder einen Toast anzeigen
          console.log("Text kopiert!");
        })
        .catch((err) => {
          console.error("Fehler beim Kopieren: ", err);
        });
    },
    async getHubData() {
      let url;
      if (process.env.NODE_ENV === "dev") {
        url = "http://127.0.0.1:5001/campusfoods/us-central1/getHubData";
      } else {
        url = "https://gethubdata-ujczn5iila-uc.a.run.app ";
      }

      try {
        const response = await axios.get(url);
        return response.data; // Die Daten der Antwort
      } catch (error) {
        console.error("Fehler beim Abrufen der Hub-Daten:", error);
        throw error; // Fehler weitergeben oder entsprechend behandeln
      }
    },
    getProductAndLocationsFromHubData(hubs) {
      let products = {};
      let locations = [];
      hubs.forEach((hub) => {
        const hubName = hub.name;
        locations.push(hubName);
        let productsArray = [];

        hub.products.forEach((product) =>{
   
          productsArray.push({
            name: product.name,
            sku: product.sku,
            box: product.box,
            Lagerbestand: product.Lagerbestand, // Annahme, dass das Feld "Lagerbestand" in jedem Produkt-Objekt vorhanden ist
            Reserviert: product.Reserviert,
            Verfügbar: product.Lagerbestand - product.Reserviert,
            Menge: 0,
          });
        })

        products[hubName] = productsArray;
      });

      return { products, locations };
    },
    validateQuantity(product) {
      if (product.Menge > product.Verfügbar) {
        product.Menge = product.Verfügbar;
      }
    },
    async submitOrder() {
      const resp = await this.placeOrder();

      const order = resp.orderData;
      const pin = order.pin;
      const orderid = order.name;
      this.pin = pin;
      this.orderId = orderid;
      const url_pre = "abholen.campusfoods.de/";
      const url = url_pre + "?id=" + orderid + "&pin=" + pin;
      this.url = url;
      this.OrderSubmitted = true;
      this.snackbar = true;
      setTimeout(() => {
        this.cleanFieldsAndAdjustQuantities();
      }, 1000);
    },
    async placeOrder() {
      let url;
      if (process.env.NODE_ENV === "dev") {
        url =
          "http://127.0.0.1:5001/campusfoods/us-central1/createOrderInternal";
      } else {
        url = "https://createorderinternal-ujczn5iila-uc.a.run.app";
      }
      let config = {
               headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  // 'Content-Type': 'application/json',
               } 
          }
      var data = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        orderid: this.orderId,
        location: this.selectedLocation,
        products: this.products[this.selectedLocation],
        // "pickupdate" : this.pickupdate,
      };
      console.dir(data)
      try {
      
        const response = await axios.post(url, qs.parse(data), config);
        return response.data; // Die Daten der Antwort
      } catch (error) {
        console.error("Fehler beim Abrufen der Hub-Daten:", error);
        throw error; // Fehler weitergeben oder entsprechend behandeln
      }
    },
    //     async fetchAndProcessHubData() {
    //     const url = '{url}/getHubData'; // Ersetze {url} mit der tatsächlichen URL deiner Cloud Function
    //     try {
    //         const response = await axios.get(url);
    //         const hubs = response.data;

    //         hubs.forEach(hub => {
    //             if (hub.products && Array.isArray(hub.products)) {
    //                 hub.products = hub.products.map(product => {
    //                     return { ...product, Menge: 0 };
    //                 });
    //             }
    //         });

    //         return hubs;
    //     } catch (error) {
    //         console.error('Error fetching hub data:', error);
    //         throw new Error('Failed to fetch hub data');
    //     }
    // }
    copyToClipboard() {
      // Hier kannst du die Logik für das Kopieren in die Zwischenablage hinzufügen
      const textToCopy = `BESTELLNUMMER: ${this.orderId}\nPIN: ${this.pin}\nURL: ${this.url}`;
      navigator.clipboard.writeText(textToCopy);
      // .then(() => {
      //   this.$snackbar.open('In die Zwischenablage kopiert');
      // })
      // .catch((error) => {
      //   console.error('Fehler beim Kopieren: ', error);
      //   this.$snackbar.open('Fehler beim Kopieren');
      // });
    },
    cleanFieldsAndAdjustQuantities() {
      this.firstname = null;
      this.lastname = null;
      this.email = null;

      this.products[this.selectedLocation].forEach((product) => {
        if (typeof product.Reserviert == "string") {
          product.Reserviert = parseInt(product.Reserviert);
        }
        if (typeof product.Menge == "string") {
          product.Menge = parseInt(product.Menge);
        }

        product.Reserviert = product.Reserviert + product.Menge;
        product.Menge = 0;
        product.Verfügbar = product.Lagerbestand - product.Reserviert;
      });
      // this.pin = null;
      // this.url = null;
      // this.orderId = null;
    },
    closeSnackbar() {
      this.snackbar = false;
      this.pin = null;
      this.url = null;
      this.orderId = null;
    },
    confirmSubmit() {
      this.dialogConfirm = false;
      this.submitOrder();
    },
  },
};
</script>
<template>
  <div>
    <h1 class="text-center">Interne Bestellungen</h1>
    <v-raw v-show="locations.length > 0">
      <v-col>
        <v-select
          label="Standort"
          :items="locations"
          v-model="selectedLocation"
        ></v-select>
      </v-col>
      <v-col v-if="selectedLocation != null">
        <v-data-table :items="products[selectedLocation]">
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.Menge="{ item }">
            <v-text-field
              type="number"
              v-model="item.Menge"
              @input="validateQuantity(item)"
              :max="item.Verfügbar"
              min="0"
            ></v-text-field>
          </template>
        </v-data-table>
        <v-form class="mt-10">
          <v-text-field v-model="orderId" label="Bestellnummer"> </v-text-field>

          <v-text-field v-model="firstname" label="Vorname"> </v-text-field>
          <v-text-field v-model="lastname" label="Nachname"> </v-text-field>
          <v-text-field v-model="email" label="E-Mail"> </v-text-field>
          <v-snackbar v-model="snackbar" :timeout="-1">
            Bestellnummer: {{ orderId }}<br />
            PIN: {{ pin }}<br />
            URL: {{ url }}
            <br />

            <v-btn color="primary" text @click="copyToClipboard">
              Kopieren
            </v-btn>
            <template v-slot:actions>
              <v-btn color="red" variant="text" @click="closeSnackbar">
                Close
              </v-btn>
            </template>
            <!-- Bestellung erfolgreich abgeschickt -->
          </v-snackbar>
          <div>
            <!-- Ihr Button -->
            <v-btn class="cf-btn-main mt-4" block @click="dialogConfirm = true">
              Bestellung abschicken
            </v-btn>

            <!-- Bestätigungsdialog -->
            <v-dialog v-model="dialogConfirm" persistent max-width="300">
              <v-card>
                <v-card-title class="headline">Bestätigung</v-card-title>
                <v-card-text
                  >Möchten Sie die Bestellung wirklich abschicken?</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="confirmSubmit"
                    >Ja</v-btn
                  >
                  <v-btn
                    color="red darken-1"
                    text
                    @click="dialogConfirm = false"
                    >Abbrechen</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-form>
      </v-col>
    </v-raw>
  </div>
</template>