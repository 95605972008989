<script setup>
import { db, auth } from "@/firebase/index.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, getDocs, setDoc, addDoc, doc } from "firebase/firestore";
</script>
<script>
import { onMounted } from "vue";
export default {
  // setup() {
  //     onMounted(async () => {
  //         const response = await fetch("http://127.0.0.1:5001/campusfoods/us-central1/getOrdersToPickup");
  //         const ordersToPick = await response.json();
  //         console.dir(ordersToPick)
  //         // this.ordersToPick = ordersToPick;
  //     });
  // },
  name: "PicklistView",
  data: () => ({
    ordersToPick: null,
    selectedOrder: null,
    dialog: false,
    onlyToday: false,
  }),
  methods: {
    async getOrdersToPick() {
        console.dir(this.onlyToday)
      const response = await fetch(
        "http://127.0.0.1:5001/campusfoods/us-central1/getOrdersToPack",
        {        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({onlyToday : this.onlyToday}),}
        
      );
      const data = await response.json();
      this.ordersToPick = data.orders;
      return data;
    },
    async markAsPacked() {
      console.dir("MARKING AS PACKED");
      console.dir(this.selectedOrder);
    },
    async open_box(box) {
      console.dir("GOING TO OPEN BOX");
      console.dir(box);
      //   await setDoc(doc(collection(db, "AdminLockRequests")), {
      //     user_mail: this.user.email,
      //     box: box,
      //     timestamp: new Date().toISOString(),
      //     request_status: "open",
      //   });

      //   setTimeout(() => {
      //     this.dialog = false;
      //   }, 3000);
    },
  },
};
</script>
<template>
  <div>
    <div class="mb-5 text-center justify-center">
      <v-btn @click="getOrdersToPick">Pickliste erstellen</v-btn>

        <v-switch
        class="center-container"
        v-model="onlyToday"
        label="nur heute"
        color="orange"
      ></v-switch>


    </div>


    <v-card v-if="selectedOrder != null" color="#F5F5DC" class="mb-5">
      <v-card-title
        >{{ selectedOrder.first_name }} {{ selectedOrder.last_name }} ({{
          selectedOrder.name
        }})</v-card-title
      >
      <v-card-text>
        <v-list
          density="compact"
          class="justify-center"
          style="background-color: #f5f5dc"
          color="#FFFFF"
        >
          <v-list-item v-for="(box, i) in selectedOrder.boxes" :key="i">
            <v-card color="#F5F5DC" class="border">
              <v-card-title class="text-center justify-center">
                {{ box.boxName }}</v-card-title
              >
              <v-card-text>
                <v-list
                  density="compact"
                  class="justify-center"
                  style="background-color: #f5f5dc"
                  color="#FFFFF"
                >
                  <v-list-item v-for="(item, i) in box.items" :key="i">
                    <v-list-item-title
                      >{{ item.quantity }} x {{ item.name }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-dialog
                    activator="parent"
                    width="auto"
                    transition="dialog-bottom-transition"
                  >
                    <v-card>
                      <v-card-text>
                        Möchtest du Box {{ box.boxName }} öffnen?
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="green" block @click="open_box(box)"
                          >Öffnen</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list>
              </v-card-text>
            </v-card>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="text-center justify-center">
        <v-btn color="green" variant="outlined" @click="markAsPacked()"
          >Als kommissioniert markieren</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-list
      v-if="ordersToPick != null && ordersToPick.length > 0 "
      density="compact"
      class="justify-center"
      style="background-color: beige"
      color="#FFFFF"
    >
      <v-list-item
        v-for="(order, i) in ordersToPick"
        :key="i"
        @click="selectedOrder = order"
      >
        <v-list-item-title
          >{{ order.first_name }} {{ order.last_name }} ({{
            order.name
          }})</v-list-item-title
        >
        <v-list-item-subtitle>
          {{ order.pickup_date }} : {{ order.boxNames }}
        </v-list-item-subtitle>
        <v-divider class="mt-2"></v-divider>
      </v-list-item>
    </v-list>
  </div>
</template>
<style>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; Adjust this to match your desired height */
}
</style>