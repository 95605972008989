<template>
  <v-container>
    <v-select v-model="selectedHub" :items="hubOptions" label="HUB filtern" @update:model-value="filterOrders"></v-select>
    <v-data-table :headers="headers" :items="filteredOrders" item-key="orderIdShopify" class="elevation-1">
      <!-- Datensatzzeilen und Aktionen -->
      <template v-slot:[`item.actions`]="{ item }">

        <v-icon class="me-2" @click="showOrderDetails(item)">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteOrder(item)">
          mdi-delete
        </v-icon>
        <!-- <v-btn color="#add8e6" @click="showOrderDetails(item)" class="mx-1">
          Details
        </v-btn>
        <v-btn color="#deb887" @click="deleteOrder(item)" class="my-1">
          Löschen
        </v-btn> -->
      </template>
    </v-data-table>
    <order-details v-if="selectedOrder" :order="selectedOrder" @close="selectedOrder = null"></order-details>
  </v-container>
</template>

<script>
import axios from 'axios';
import OrderDetails from '@/components/OrderDetails.vue';
export default {
  name: 'InternalOrdersView',
  components: {
    OrderDetails
  },
  data() {
    return {
      orders: [],
      filteredOrders: [],
      selectedHub: '',
      selectedOrder: null,
      hubOptions: [],
      headers: [
        { title: 'Bestellnummer', key: 'orderIdShopify' },
        { title: 'Vorname', key: 'first_name' },
        { title: 'Nachname', key: 'last_name' },
        { title: 'HUB', key: 'pickup_location' },
        { title: 'PIN', key: 'pin' },
        { title: 'Status', key: 'status' },
        { title: 'Aktionen', key: 'actions', sortable: false }
      ]
    }
  },
  mounted() {
    this.fetchOrders();
  },
  computed: {

  },
  methods: {
    showOrderDetails(order) {
      this.selectedOrder = order;
    },
    async fetchOrders() {
      try {
        let url;

        let config = {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          }
        }
        if (process.env.NODE_ENV === 'dev') {
          url = "http://127.0.0.1:5001/campusfoods/us-central1/getInternalOpenOrders";
        }
        else {
          url = "https://getinternalopenorders-ujczn5iila-uc.a.run.app"
        }

        var response = await axios.get(url, config);

        this.orders = response.data;


      } catch (error) {
        console.error('Fehler beim Abrufen der Bestellungen:', error);
      }
      this.updateHubOptions();
      console.dir(this.hubOptions)
      this.filterOrders();
      console.dir(this.filteredOrders)
    },

    updateHubOptions() {
      const hubs = new Set(this.orders.map(order => order.pickup_location));
      this.hubOptions = Array.from(hubs);
    },
    filterOrders() {

      if (this.selectedHub) {
        this.filteredOrders = this.orders.filter(order => order.pickup_location === this.selectedHub);

      } else {
        this.filteredOrders = this.orders;

      }
    },
    async deleteOrder(order) {
      let url;
      if (process.env.NODE_ENV === 'dev') {
        url = `http://127.0.0.1:5001/campusfoods/us-central1/deleteInternalOrder/${order.name}`;
      }
      else {
        url = `https://deleteinternalorder-ujczn5iila-uc.a.run.app/${order.name}`
      }
      try {

        await axios.delete(url);
        this.orders = this.orders.filter(o => o.name !== order.name);
      } catch (error) {
        console.error('Fehler beim Löschen der Bestellung:', error);
      }
    }
  },
  watch: {
    orders: {
      handler() {
        this.updateHubOptions();
      },
      deep: true
    }
  },
}
</script>
<style>
.closed-order {
  background-color: #f0f0f010;
}
</style>