<template>
  <v-app style="background-color: #DCEDC8; max-width: 1000px; margin: 0 auto;">
    <Header />
    <v-main class="mx-5 my-5" >
      <router-view />
      
    </v-main>
    <Footer />
  </v-app>
</template>

<script setup>
  import Header from "./components/Header.vue" 
  import Footer from './components/Footer.vue';
</script>
<script>

export default {
  name: "App",
  components: {
    Footer,
    Header
  },
  mounted() {
    // set document title
    document.title = "Pickup Portal";
  },
  methods: {

  },
  
  data: () => ({
    //
  }),
  head: {
       link: [
             {
                 rel: "icon",
                 href: require("./assets/logo_cf.svg")
             },
            ]
       }
   
};
</script>
<style>
@import './style.css';
</style>