import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SelectView from "@/views/SelectView.vue"
import PickupView from "@/views/PickupView.vue";
import VerifyGeoView from "@/views/VerifyGeoView.vue";
import AdminView from "@/views/AdminView.vue";
import PicklistView from "@/views/PicklistView.vue";
import DatenschutzView from "@/views/DatenschutzView.vue";
import InternalCreateOrderView from "@/views/InternalCreateOrderView.vue";
import InternalStorageViewVue from "@/views/InternalStorageView.vue";
import InternalOrdersView from "@/views/InternalOrdersView.vue";
import InternalView from "@/views/InternalView.vue";
import InternalLoginView from "@/views/InternalLoginView.vue";
import { userIsAuthenticated } from "@/firebase/authService";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    name: "home_two",
    component: HomeView,
  },
  {
    path: "/select",
    name: "select",
    component: SelectView,
  },
  {
    path: "/pickup",
    name: "pickup",
    component: PickupView,
  },
  {
    path: "/verify",
    name: "verify",
    component: VerifyGeoView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
  {
    path: "/picklist",
    name: "picklist",
    component: PicklistView,
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: DatenschutzView,
  },
  {
    path: '/intern/login',
    name: 'internalLogin',
    component: InternalLoginView
  },
  {
    path: "/intern",
    name: "intern",
    component: InternalView,
    children: [
      {
        path: "orders",
        name: "orders",
        component: InternalOrdersView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "createOrder",
        name: "createOrder",
        component: InternalCreateOrderView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "storage",
        name: "storage",
        component: InternalStorageViewVue,
        meta: {
          requiresAuth: true
        }
      }
    ],
    meta: {
      requiresAuth: true
    }
  },

  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = await userIsAuthenticated();

  if (requiresAuth && !isAuthenticated) {
    next({ name: 'internalLogin', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});
export default router;
