<script setup>
import { useUserStore } from "@/store/index.js";
// import { QrStream } from "vue3-qr-reader";
// import { QrcodeStream } from '../../../../src'
import ProductTableVue from "@/components/ProductTable.vue";
import QRScanner from "@/components/QRScanner.vue";
import { collection, addDoc } from "firebase/firestore"; 
import { db } from "@/firebase/index.js";
</script>

<template>
  <div>
    <v-alert
      v-if="!checkIfOrderIsValidToPickup()"
      type="error"
      title="Achtung!"
    >
      <!-- Heute ist nicht der Abholtag ({{
        order.pickup_date.split("-").reverse().join(".")
      }}) oder deine Bestellung wurde noch nicht gepackt. Bitte komme an dem
      geplanten Tag wieder oder kontaktiere uns. -->
      Deine Bestellung wurde bereits abgeholt. Sollte dies nicht der Fall sein, melde dich bitte bei unseren Mitarbeitern.
    </v-alert>
    <div v-else>
      <p>Du möchtest folgende Produkte abholen:</p>
      <ProductTableVue />
      <!-- <v-card v-show="showEntryMsg">
      <v-card-text>
        Du möchtest folgende Produkte abholen:
        
        <br>
        Wir führen dich durch den Prozess. Du bist nur noch wenige Clicks von
        deiner Abholung entfernt. Falls Fragen aufkommen, kannst du uns
        jederzeit über den Button unten kontaktieren.
      </v-card-text>
      <v-btn @click="toggleEntryMsg()" color="green"
        >Weiter mit Verifizierung</v-btn
      >
    </v-card> -->

      <v-alert v-if="showLocationNotification" class="mt-6 text-center">
        Wir benötigen deinen Standort um sicherzustellen, dass du dich in der
        Nähe der Boxen befindest. Ist das für dich okay? <br />
        <v-btn class="mx-2" color="green" @click="verifyGeo()"
          >Ist in Ordnung</v-btn
        >

        <v-btn
          class="mx-2"
          @click="
            AllowedGeo = false;
            showLocationNotification = false;
          "
          color="red"
          >Nein</v-btn
        >
      </v-alert>
      <div v-if="!showLocationNotification" class="text-center mt-4">
        <div v-if="AllowedGeo">
          <div v-if="successGeo">
            <p>
              Super, vielen Dank im nächsten Schritt kannst du deine Produkte
              entnehmen
            </p>
          </div>
          <div v-else>
            <p>
              Du bist entweder nicht in der Nähe oder dein Standort ist nicht
              freigegeben. Bitte versuche es erneut oder nutze die manuelle
              Verifizierung mittels QR Code.
            </p>
            <v-btn color="#FAFAD2" @click="verifyGeo()">Erneut probieren</v-btn>
            <v-btn color="#FAFAD2" @click="AllowedGeo = false"
              >QR-Code nutzen</v-btn
            >
          </div>
        </div>
        <div v-else>
          <div class="text-center">
            Scanne den QR-Code auf einer der Boxen, um zu verifizieren, dass du
            dich in der Nähe befindest.
          </div>
          <br />
          <QRScanner></QRScanner>
        </div>
        <div
          v-show="successGeo || useUserStore().qr_scanned"
          justify="center"
          class="mt-6"
        >
          <v-btn color="#7DB33D" @click="$router.push('pickup')"
            >Produkte entnehmen</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var TARGET_LATITUDE = 52.3089;
var TARGET_LONGITUDE = 8.03704;
var MAX_DISTANCE = 400; // Meters
export default {
  name: "VerifyGeoView",
  data() {
    return {
      manualInput: "",
      showLocationNotification: true,
      AllowedGeo: false,
      successGeo: false,
      showQRCodeScanner: false,
      showEntryMsg: true,
      showManualInput: false,
      showErrorMessage: false,
      order: useUserStore().order,
    };
  },
  components: { ProductTableVue, QRScanner },
  methods: {
    showManualVerification() {
      this.showQRCodeScanner = false;
      this.showErrorMessage = false;
      this.showManualInput = true;
    },
    toggleEntryMsg() {
      this.showQRCodeScanner = true;
      this.showEntryMsg = false;
    },
    validate() {
      if (this.manualInput == this.order.location_hub) {
        this.showQRCodeScanner = false;
        this.showErrorMessage = false;
        // this.$router.push({ name: 'PickupSuccess' })
      } else {
        this.showErrorMessage = true;
      }
    },
    isDateWithinDaysInPast(receivedDate, days) {
      // TODO: Check logic. It doesnt behave as expected
      const parsedDate = new Date(receivedDate);

      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0); // Set the time to the start of the day
      const startOfNextDay = new Date(startOfToday);
      startOfNextDay.setDate(startOfToday.getDate() + 1);
      const startOfRange = new Date(startOfToday);
      startOfRange.setDate(startOfToday.getDate() - days); // Calculate the start date of the range

      return parsedDate >= startOfRange && parsedDate < startOfNextDay;
    },
    checkIfOrderIsValidToPickup() {
      // if (this.order.name.startsWith("DUMMY")) {
      //   return true;
      // }
      if(this.order == null) {
        return false;
      }
      if(this.order.is_internal == true) {
        if(this.order.status != "closed") {
          return true;
        }
        else {
          return false;
        }
    
      }
      const first = this.isDateWithinDaysInPast(this.order.pickup_date, 1);
      const second = this.order.status == "ready_to_pickup" || this.order.status == "ready";
     
      return [first, second].every((x) => x === true);
    },
    isUserNearTargetLocation(
      userLatitude,
      userLongitude,
      targetLatitude,
      targetLongitude,
      maxDistance
    ) {
      const latDiff = Math.abs(userLatitude - targetLatitude);
      const lonDiff = Math.abs(userLongitude - targetLongitude);
      const maxDiff = Math.max(latDiff, lonDiff);
      const distanceInMeters = maxDiff * 111000; // Approximate meters per degree difference
      return distanceInMeters <= maxDistance;
    },
    handlePosition(position) {
      // const { latitude, longitude } = position.coords;
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      const targetLocation = {
        latitude: TARGET_LATITUDE,
        longitude: TARGET_LONGITUDE,
      };
      var isNear = this.isUserNearTargetLocation(
        latitude,
        longitude,
        targetLocation.latitude,
        targetLocation.longitude,
        MAX_DISTANCE
      );
      if (location.hostname === "localhost") {
        isNear = true;
      }
      if (isNear) {
    
        this.AllowedGeo = true;
        this.showLocationNotification = false;
        this.successGeo = true;
        return true;
      } else {
        this.AllowedGeo = true;
        this.showLocationNotification = false;
        this.successGeo = false;

    
        return false;
      }
    },
    async create_preperation_request() {
      var userStore = useUserStore();

      // get mac adress from every box
      let doc_ids = {};
      for (var box of userStore.order.boxes) {
        let docRef = await addDoc(collection(db, "requests"), {
          mac_address: box.lock_mac,
          user_code: box.lock_user_code,
          preparation_request: true,
          unlock_trigger: false,
          timestamp: new Date().toISOString(),
        });
  
        box.docId = docRef.id;
        doc_ids[box.lock_mac] = docRef.id;
      }
      userStore.doc_ids = doc_ids;
      // Add Firebase Function "createPreperationRequest"
      // Add Firebase Firestore set "pickup_start" : TIMESTAMP
    },
    async verifyGeo() {
      // await navigator.geolocation;
      navigator.geolocation.getCurrentPosition(
        this.handlePosition,
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
          this.AllowedGeo = false;
          this.showLocationNotification = false;
        }
      );
      setTimeout(async () => {
        if (this.successGeo) {
        await this.create_preperation_request();
      }
      }, 2000);
      console.dir(this.successGeo);

    },
  },
};
</script>

<style>
</style>