
<script setup>
import products from './products.json';
import axios from 'axios';
import qs from 'qs';
</script>
<script>
export default {
  data() {
    return {
      products: products,
      selectedProduct: {
        name: '',
        sku: ''
      },
      product: {
        name: '',
        sku: '',
        box: '',
        Lagerbestand: 0,
        Reserviert: 0,
 
      },
      boxes: ["Haste-01","Haste-02", "Haste-03", "Haste-04"] 
    };
  },
  props: {
    hub: String
  },
  computed: {
    productNames() {
      return this.products.map(product => ({
        name: product.name,
        sku: product.sku
      }));
    }
  },
  mounted() {
    if (this.products.length > 0) {
      this.selectedProduct = {
        name: this.products[0].name,
        sku: this.products[0].sku
      };
  }
},
  methods: {
    submitProduct() {
   
      
      this.product.name = this.selectedProduct.name;
      this.product.sku = this.selectedProduct.sku;
      console.dir(this.selectedProduct)
      console.dir(this.product);
      let product_to_send = {
        "name": JSON.parse(JSON.stringify(this.selectedProduct.name)),
        "sku": JSON.parse(JSON.stringify(this.selectedProduct.sku)),
        "box": this.product.box,
        "Lagerbestand": this.product.Lagerbestand,
        "Reserviert": this.product.Reserviert
      }
      console.dir(product_to_send)
      let url
      if (process.env.NODE_ENV === 'dev') {
          url = "http://127.0.0.1:5001/campusfoods/us-central1/addProductToHub"
        }
        else {
          // TODO: CHANGE AFTER PUBLIC DEPLOYMENT
          url = "https://addproducttohub-ujczn5iila-uc.a.run.app"
        }
      let data = {
        product : product_to_send,
        hub : this.hub
      }
      data = qs.parse(data)
      axios.post(url, data)
        .then(() => {
          console.log('Produkt erfolgreich hinzugefügt');
          this.$emit('product-added', true);
        })
        .catch(error => {
          console.error('Fehler beim Hinzufügen des Produkts:', error);
          this.$emit('product-added', false);
        });
      // Logik zum Senden der Daten an die Cloud Function
      // Code zum Schließen des Dialogs hier
    },
    onProductSelect(selected) {
      console.dir(selected)
    this.selectedProduct = selected;
    // Führen Sie hier zusätzliche Aktionen durch, z.B. das Aktualisieren anderer abhängiger Daten
  }
  },

};
</script>
<template>
  <v-card>
    <v-card-title>Produkt zum Hub hinzufügen</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-select
            label="Produkt auswählen"
            v-model="selectedProduct"
            :items="products"
       
            item-title="name"
            return-object
            @change="onProductSelect"
          ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field label="SKU" v-model="selectedProduct.sku" readonly></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6">
            <v-text-field label="Box" v-model="product.box" required></v-text-field>
          </v-col> -->
          <v-col cols="12" sm="6">
            <v-select label="Box" v-model="product.box" :items="boxes" required></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field label="Lagerbestand" v-model.number="product.Lagerbestand" type="number" required></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field label="Reserviert" v-model.number="product.Reserviert" type="number" required></v-text-field>
          </v-col>
          <!-- <v-col cols="12" sm="6">
            <v-text-field label="Menge" v-model.number="product.Menge" type="number" required></v-text-field>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="submitProduct">Hinzufügen</v-btn>
    </v-card-actions>
  </v-card>
</template>
