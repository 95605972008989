<script setup>
import {QrStream} from "vue3-qr-reader";
import {useUserStore} from "@/store/index.js";
</script>
<script>
export default {
components: {QrStream},
data() {

return {
    
}
},

methods: {
    onDecode: async function (decodedString) {
        if(decodedString == "ABCDEFG123"){
            useUserStore().qr_scanned = true;
        }
    }
}
}
</script>
<template>
    <v-row justify="center">
  
  <qr-stream   @decode="onDecode" style="max-height:500px; max-width: 500px;" class="text-center"><b>QR Code scannen</b></qr-stream>

  </v-row>
</template>