<script setup>
import { useUserStore } from "@/store/index.js";
import axios from 'axios';
import qs from 'qs';
</script>

<script>
export default {
  name: "PickupBoxComponent",
  props: {
    box: Object,
  },
  emits: ['box-status-updated'],
  data() {
    return {
      order: useUserStore().order,
      doc_ids: useUserStore().doc_ids,
      lock_opened: false,
      opening_process: false,
      error_at_opening: false,
      error_at_opening: false
    };
  },
  methods: {

    // async openBoxDEV() {
    //   this.opening_process = true;
    //   var successful = false;
    //   // TODO: IMPLEMENT OPEN BOX WITH API

    //   setTimeout(() => {
    //     successful = true;
    //     this.lock_opened = true;
    //     this.opening_process = false;
    //   }, 3000);
    //   if (successful == true) {
    //     this.order.boxes.find(
    //       (box) => box.boxid != this.box.boxid
    //     ).is_picked_up = true;
    //     this.opening_process = false;
    //   }
    // },
    checkBoxLockStatus(docId) {
      return new Promise((resolve, reject) => {
        let intervalId = null;
        let timeoutReached = false;
        var url
        if (process.env.NODE_ENV === 'dev') {
          url = "http://127.0.0.1:5001/campusfoods/us-central1/getBoxLockStatus"
        }
        else {
          // TODO: CHANGE AFTER PUBLIC DEPLOYMENT
          url = "https://getboxlockstatus-ujczn5iila-uc.a.run.app"
        }
        const queryStatus = async () => {
          let data = qs.parse({ docId: docId })
          let config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              // 'Content-Type': 'application/json',
            }
          }
          try {
            var response = await axios.post(url, data, config);
            if (response.data.has_opened) {
              clearInterval(intervalId);
              resolve(true); // Schloss wurde geöffnet
            }
          } catch (error) {
            console.error("Error querying lock status:", error);
          }
        };

        intervalId = setInterval(queryStatus, 5000); // Wiederhole alle 5 Sekunden

        setTimeout(() => {
          timeoutReached = true;
          clearInterval(intervalId);
          reject(new Error("Timeout reached without opening the lock"));
        }, 60000); // Timeout nach 60 Sekunden
      });
    },
    async openBox() {
      console.dir("Opening box");

      this.opening_process = true;
      let docId = this.box.docId;
      var functionUrl = process.env.NODE_ENV === 'dev' ?
        `http://127.0.0.1:5001/campusfoods/us-central1/openBoxAndListen` :
        `https://openboxandlisten-ujczn5iila-uc.a.run.app`;
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Content-Type': 'application/json',
        }
      }
      try {
        let data = qs.parse({ docId: docId })
        // Initiieren des Öffnungsprozesses
        await axios.post(functionUrl, data, config);

        // Warten auf das Ergebnis der Überprüfung, ob das Schloss geöffnet wurde
        await this.checkBoxLockStatus(docId);

        // Wenn das Schloss geöffnet wurde
        console.log("Lock has been opened");
        this.successful = true;
        this.lock_opened = true;
        this.$emit('box-status-updated', true);
      } catch (error) {
        console.error("Error opening the lock:", error);
        this.successful = false;
        this.lock_opened = false;
        this.error_at_opening = true;
        
        this.$emit('box-status-updated', false);
      } finally {
        this.opening_process = false;
      }
    }

  },
};
</script>

<template>
  <div v-if="!lock_opened && !opening_process && error_at_opening" style="margin-bottom: 5px">
    <v-alert color="error">
      <v-icon color="red" class="my-4">mdi-lock-outline</v-icon> Etwas ist
      schiefgelaufen. Bitte versuche es erneut oder kontaktiere uns.</v-alert>
  </div>
  <div>
    <v-card style="background-color: beige">
      <v-card-title class="text-center">
        <span class="text-h5"> Box {{ box.boxName }}</span>
      </v-card-title>
      <v-card-text class="text-center" v-if="!lock_opened && !opening_process">
        <p>Die Box enthält folgende Produkte</p>

        <v-table dense style="background-color: beige">
          <thead class="text-center">
            <tr>
              <th class="text-center">Menge</th>
              <th class="text-center">Produktname</th>
            </tr>
          </thead>
          <tbody color="#f0f0f0" v-for="item in box.items" v-bind:key="item.name">
            <tr v-if="!item.name.includes('Pfand')">
              <td class="text-center">{{ item.quantity }}</td>
              <td class="text-center">{{ item.name }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
      <div class="text-center mx-10">
        <v-btn v-show="!lock_opened && !opening_process" size="x-large" @click="openBox()" class="text-center my-6"
          color="green">Box öffnen</v-btn>
        <v-progress-circular v-if="!lock_opened && opening_process" indeterminate color="green"
          class="my-6"></v-progress-circular>
        <div v-if="lock_opened && !opening_process">
          <v-icon color="green" class="my-4">mdi-lock-open-outline</v-icon>
          Entnehme deine Ware &#128522;
        </div>

      </div>
    </v-card>
  </div>
</template>