<script setup>
import { useUserStore } from "@/store/index.js";
import GoogleMaps from "@/components/GoogleMaps.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import ProductTableVue from "@/components/ProductTable.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, computed } from "vue";
import { onMounted } from 'vue'
import router from "@/router/index.js"
const cors = require("cors")({ origin: true });


</script>
<script >
export default {
  components: { VueDatePicker, GoogleMaps, ProductTableVue },
  data() {
    return {
      showMap: false,
      date: null,
      user: useUserStore().order,
      dateCurrent: ref(new Date()),
      has_error_at_submit: false,
      has_success_at_submit: false,
      overlay: false,
      selectedLocation: "Hafen", // Make configurable later when needed.
      requiredBoxes: 2,
      allowedDates: null,
      gotDates: false,
    };
  },
  async mounted()  {
    console.dir(this.user)
    await this.getAllowedDates()
    
  },


  computed: {
    // dateMin(){return this.dateCurrent.getHours() < 12  ? this.dateCurrent : new Date(this.dateCurrent.setDate(this.dateCurrent.getDate()  + 1))},
    // dateMax(){return this.dateCurrent.getHours() < 12  ? new Date(this.dateCurrent.setDate(this.dateCurrent.getDate()  + 14)) : new Date(this.dateCurrent.setDate(this.dateCurrent.getDate()  +15)) }  ,
    dateMin() {
      return this.dateCurrent.getHours() < 12
        ? this.dateCurrent
        : new Date(this.dateCurrent.getTime() + 1 * 86400000);
    },
    dateMax() {
      return new Date(this.dateCurrent.getTime() + 14 * 86400000);
    },
    mapButtonText() {
      return this.showMap ? "Karte ausblenden" : "Karte einblenden";
    },
    already_selected() {
      return "pickup_date" in this.user ? true : false;
    },
  },
  components: {},

  async onMounted() {
   await this.getAllowedDates()

  },
  methods: {
    updateAllowedDates(){
      this.date = null

      var allowedDates = this.AllAllowedDates.map((date) => {
        if(date[this.selectedLocation] > 0){
          return new Date(date.date)
        }
        else{return null}

        
      })
      allowedDates = allowedDates.filter((date) => date != null)
      if (allowedDates.length == 0){
        // Workaround for when no dates are available. Otherwise the datepicker will show all dates
        allowedDates = [new Date(2023,0,1)]
      }
      this.allowedDates = allowedDates
    

    },
    async getAllowedDates(){
      const resp = await fetch("https://us-central1-campusfoods.cloudfunctions.net/getAllowedDates",
      {
      method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({boxes_required: this.user.boxes_required})
      })
      const data = await resp.json()
      this.AllAllowedDates = data.allowedDates
      this.updateAllowedDates() // Will later be an onChange Event when location is selected

      this.gotDates = true

    },
    async getFreeBoxesNext14Days() {
      const jetzt = new Date();
      const startDate = jetzt.toISOString().split("T")[0]; // Aktuelles Datum im ISO-Format (YYYY-MM-DD)
      const endDate = new Date(jetzt.getTime() + 13 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0]; // Datum in 14 Tagen

      const reservierungenRef = firestore.collection("Reservierungen");
      const query = reservierungenRef
        .where("Datum", ">=", startDate)
        .where("Datum", "<=", endDate);

      const snapshot = await query.get();

      if (snapshot.empty) {
        console.log(
          "Keine Reservierungen für den angegebenen Zeitraum gefunden"
        );
        return;
      }

      snapshot.forEach((doc) => {
        var occupiedBoxes = new Set();
        const reservations = doc.data().reservations;
        reservations.forEach((reservation) => {
          occupiedBoxes.add(reservation.Box);
        });
        if (occupiedBoxes.size + this.user.boxes_required > 10) {
          this.diabled_dates.push(doc.id);
        }
      });
    },
    format: (date) => {
      const weekdays = [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ];
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const weekday = weekdays[date.getDay()];
      return `${weekday} ${day}.${month}.${year}`;
    },
    async submit_pickup_date() {


      let error = false;
      if(this.user.pickup_date){
        const resp = await fetch("https://us-central1-campusfoods.cloudfunctions.net/removeOldReservation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({order: this.user}),
      })
      if(resp.status != 200){
        error = true;
      }
      }

      var sending_data = {
        order: this.user,
        pickup_date: new Date(this.date).toISOString().split("T")[0],
        location: this.selectedLocation,
      };
      this.overlay = true;
      //http://127.0.0.1:5001/campusfoods/us-central1/assignBoxes
      //https://assignboxes-ujczn5iila-uc.a.run.app
      // https://assignboxesandcreatereservation-ujczn5iila-uc.a.run.app
      fetch("https://assignboxesandcreatereservation-ujczn5iila-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sending_data),
      })
        .then((response) => response.json())
        .then((data) => {
          // Process the returned data
          this.has_success_at_submit = true;
          this.overlay = false;
          setTimeout(() => {
            this.has_success_at_submit = false;
            router.push("/")
          }, 15000);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error:", error);
          this.has_error_at_submit = true;
          this.overlay = false;
          setTimeout(() => {
            this.has_error_at_submit = false;
          }, 15000);
        });
    },
  },
};
</script>
<template>
  <div>

    <v-alert v-if="already_selected" type="warning" title="Datum bereits ausgewählt!" >
          Dein vorgemerktes Datum ist: 
          <strong>{{ user.pickup_date.split("-").reverse().join(".") }} </strong>
           Du kannst es aber gerne ändern.
           
        </v-alert>
        <br v-if="already_selected">

    <v-card class="mx-auto" color="#dbe8d8" loader-height="6" light>

      <v-card-title>
        <v-icon color="black" left> mdi-cart </v-icon>

        <span class="text-h6 font-weight-bold">Deine bestellten Produkte</span>
      </v-card-title>

      <v-card-text>
        Hallo
        <strong
          >{{ user.customer_first_name }} {{ user.customer_last_name }}</strong
        >! Nur zur Kontrolle, dies sind deine bestellten Produkte:
      </v-card-text>

      <v-card-text>
        <ProductTableVue></ProductTableVue>
        <br />
        Deine Bestellung wird auf <strong> {{ user.boxes_required }} Boxen </strong> aufgeteilt.
        <br />
        Bitte Wähle ein <strong>Datum</strong> wann du deine Produkte abholen
        möchtest.
        <br />
        <v-select
        label="Abholort (TEST!)"
        v-model="selectedLocation"
        :items="['Hafen', 'Test']"
        @update:modelValue="updateAllowedDates"
        ></v-select>
        <!-- <VueDatePicker v-model="date"  :disabled-week-days="[6, 0, 1]" :min-date="dateMin" :max-date="dateMax" ></VueDatePicker> -->
        <!-- :disabled-week-days="[6, 0, 1]" 
                  :min-date="dateMin"
          :max-date="dateMax"-->
     
        <VueDatePicker
          v-if="gotDates"
          v-model="date"
          auto-apply
          :format="format"
          :disabled-week-days="[6, 0, 1]"
          :allowed-dates = "allowedDates" 
          :enable-time-picker="false"

        >
        </VueDatePicker>

      </v-card-text>
    </v-card>

    <!-- :min="dateMin" :max="dateMax" -->
    <!-- :min-date="dateMin" :max-date="dateMax"  -->

    <v-btn
      class="mt-2"
      size="small"
      variant="outlined"
      rounded="pill"
      color="#8db795"
      @click="showMap = !showMap"
      >{{ mapButtonText }}</v-btn
    >

    <GoogleMaps class="mt-2" location="Hafen" v-show="showMap"></GoogleMaps>
    <br />
    <v-alert
      v-if="has_error_at_submit"
      class="mt-2"
      type="error"
      title="Fehler bei der Übermittlung"
      text="Es ist ein Fehler aufgetreten. Bitte versuche es erneut."
    >
    </v-alert>
    <v-alert
      v-if="has_success_at_submit"
      class="mt-2"
      type="success"
      title="Abholdatum erfolgreich gebucht"
      text="Es hat alles funktioniert. Du kannst deine Produkte am Abholdatum abholen. Überprüfe deine Mails für die PIN und weitere Informationen."
    >
    </v-alert>
    <v-btn
     
      v-show="date != null && !(has_success_at_submit == true || has_error_at_submit == true) && !overlay"
      class="mt-8"
      variant="outlined"
      rounded="pill"
      color="#0e2f16"
      filled="black"
      @click="submit_pickup_date"
    >
        <v-icon>mdi-check-bold</v-icon>Abholdatum verbindlich buchen
    </v-btn>
    <div v-if="overlay" class="text-center">
        <v-progress-circular color="amber" indeterminate> </v-progress-circular>
      </div>
  </div>
</template>
