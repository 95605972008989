<script setup>
import { auth } from "@/firebase/index.js";
import { signInWithEmailAndPassword } from "firebase/auth";</script>
<template>
        <div class="text-center">
      <v-text-field
        v-model="mail"
        label="E-mail"
        required
        clearable
      ></v-text-field>
      <v-text-field
        v-model="pwd"
        label="Password"
        type="password"
        required
        clearable
      >
      </v-text-field>
      <v-btn @click="authenticate">Login</v-btn>
    </div>
</template>
<script >
export default {
  data() {
    return {
        mail: "",
        pwd: "",
    }
  },
  methods: {
    async authenticate() {
      try {
        await signInWithEmailAndPassword(auth, this.mail, this.pwd);
        this.$emit('authenticated');
      } catch (error) {
        console.log(error);
        this.$emit('authentication-failed', error)
      }
    },
  },
}
</script>