<script setup>
import Authenticate from '@/components/Authenticate.vue';
</script>
<template>
  <div>

        <h2 class="text-center mb-5">Internes Dashboard</h2>
      <v-raw>
        <v-col>
          <router-link class="button-link" to="/intern/createOrder">
            <v-btn class="cf-btn-main" block size="large"
              >Bestellung anlegen</v-btn
            >
          </router-link>
        </v-col>
      </v-raw>
      <v-raw class="mt-10">
        <v-col>
          <router-link class="button-link" to="/intern/storage">
            <v-btn class="cf-btn-main" block size="large"
              >Bestand anpassen</v-btn
            >
          </router-link>
        </v-col>
      </v-raw>
      <v-raw>
        <v-col>
          <router-link class="button-link" to="/intern/orders">
            <v-btn class="cf-btn-main" block size="large"
              >Bestellung anschauen</v-btn
            >
          </router-link>
        </v-col>
      </v-raw>

    <router-view></router-view>
  </div>
</template>
  
  <script>
  import { userIsAuthenticated } from '@/firebase/authService';
export default {
  name: "Dashboard",
  components: {
    Authenticate,
  },
  data() {
    return {
      isAuthenticated: false,
    };
  },
  methods:   { 
    async checkAuthentication() {
      this.isAuthenticated = await userIsAuthenticated();
    },
    onAuthenticated() {
      this.isAuthenticated = true;
    }
  },
};
</script>
  <style>
.button-link {
  color: black;
  text-decoration: none; /* Entfernt die Textdekoration (Unterstreichung) */
}
</style>