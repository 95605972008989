<script setup>
import {useUserStore} from "@/store/index.js";
</script>
<script>
export default {
    data() {

return {
    order : useUserStore().order,
}
},
}
</script>
<template>
    <div>
        <v-table dense style="background-color: #FAFAD2;">
      
      <thead>
        <tr>
          <th class="text-center">Menge</th>
          <th class="text-center">Produktname</th>
        </tr>
      </thead>
      <tbody
        color="#f0f0f0"
        v-for="item in order.items"
        v-bind:key="item.productIdShopify"
      >
        <tr v-if="!item.name.includes('Pfand')">
          <td class="text-center">{{ item.quantity }}</td>
          <td class="text-center">{{ item.name }}</td>
        </tr>
      </tbody>

  </v-table>
    </div>
</template>