<script>
export default {
  name: "Footer",

  data: () => ({
  })
};
</script>

<template >
    
  <div class="text-center mt-4"  >    
    <v-footer class="d-flex flex-column" style="background-color: #DCEDC8; max-width: 800px; margin: 0 auto;" >
      <!-- app bottom fixed padless -->
        <div class="text-center mt-4" >
            {{ new Date().getFullYear() }} — <strong><a href="https://www.campusbier.de" style="text-decoration:none; color:black">Campusfoods</a></strong> - <strong><a style="text-decoration:none; color:black" href="https://abholen.campusfoods.de/datenschutz">Datenschutz</a></strong>
        </div>

    </v-footer>
  </div>
</template>
