<script>
import PickupBox from "@/components/PickupBox.vue";
import { useUserStore } from "@/store/index.js";
import axios from 'axios';
import qs from 'qs';
export default {
  name: "PickupView",
  components: {
    PickupBox
  },
  data() {
    return {
      order: useUserStore().order,
      boxStatuses: {},
      openingProcess: false,
      showSomethingWrongDialog: false, // Zustand für das Anzeigen des Dialogs
      snackbar: false, // Zustand für das Anzeigen der Snackbar
      snackbarText: '', // Text der Snackbar
    };
  },
  created() {
    this.triggerPickupFallbackCheck();
  },
  methods: {
    updateBoxStatus(boxName, isOpened) {

      this.boxStatuses[boxName] = isOpened;
    },
    triggerPickupFallbackCheck() {
      const orderId = this.order.orderId;
      const boxes = this.order.boxes; 
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Content-Type': 'application/json',
        }
      }
      var url
      if(process.env.NODE_ENV === 'dev') {
        url = "http://127.0.0.1:5001/campusfoods/us-central1/PickupFallbackCheck"
        return;
      }
      else{
        url = 'https://pickupfallbackcheck-ujczn5iila-uc.a.run.app'
      }
      let data = qs.parse({ orderId: orderId, boxes: boxes })
      axios.post(url, data, config)
        .then(() => {
          console.log('PickupFallbackCheck-Funktion ausgelöst');
        })
        .catch(error => {
          console.error('Fehler beim Auslösen der PickupFallbackCheck-Funktion:', error);
        });
    },
    finalizeOrder() {
      this.openingProcess = true;
      const allBoxesOpened = Object.values(this.boxStatuses).every(status => status === true);

      if (allBoxesOpened) {
        this.closeOrder();
      } else {
        this.showSomethingWrongDialog = true; // Zeigt den Dialog an, wenn nicht alle Boxen geöffnet wurden
      }
    },
    async closeOrder() {
      let url
      if(process.env.NODE_ENV === 'dev') {
        url = "http://127.0.0.1:5001/campusfoods/us-central1/CloseOrderAfterPickup"
      } else{
        url = 'https://closeorderafterpickup-ujczn5iila-uc.a.run.app'
      }
      console.dir(this.order)
      let data = qs.parse({ order: this.order, allBoxesOpened : true })
      // Cloud Function aufrufen
    await axios.post(url, data)
      this.openingProcess = false;
      this.snackbarText = 'Bestellung erfolgreich abgeschlossen!'; // Setzt den Snackbar-Text für eine erfolgreiche Aktion
      this.snackbar = true; // Zeigt die Snackbar an
      this.openingProcess = false;
      setTimeout(() => {
      this.$router.push('/');
    }, 5000);
    },
    async handleError() {
      let url
      if(process.env.NODE_ENV === 'dev') {
        url = "http://127.0.0.1:5001/campusfoods/us-central1/CloseOrderAfterPickup"
      } else{
        url = 'https://closeorderafterpickup-ujczn5iila-uc.a.run.app'
      }
      
      let data = qs.parse({ order: this.order, allBoxesOpened : false })
      // Cloud Function aufrufen
    await axios.post(url, data)
      this.openingProcess = false;
      this.snackbarText = 'Entschuldigen Sie vielmals! Ein Mitarbeiter wird sich zeitnah melden und die Angelegenheit klären.'; // Setzt den Snackbar-Text für den Fehlerfall
      this.snackbar = true; // Zeigt die Snackbar an
      this.openingProcess = false;
    },

  },
};
</script>
<template>
  <v-alert class="justify-center" color="#ffb11c">
    Denk bitte dran, die Box nach der Entnahme zu schließen!
  </v-alert>
  <div class="my-4" v-for="(box, i) in order.boxes" :key="i">
    <PickupBox :box="box" @box-status-updated="updateBoxStatus(box.boxName, $event)" />
  </div>
  <div class="text-center mt-10">
    <v-btn size="x-large" color="#2d572c" @click="finalizeOrder" :disabled="openingProcess">
      Bestellung abschließen
    </v-btn>
  </div>

  <!-- Vuetify Dialog -->
  <v-dialog v-model="showSomethingWrongDialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="text-h5">Boxen nicht vollständig geöffnet</v-card-title>
      <v-card-text>Einige Boxen wurden nicht geöffnet. Alles in Ordnung?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="closeOrder">Ja, alles in Ordnung</v-btn>
        <v-btn color="red darken-1" text @click="handleError">Nein, Problem melden</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="snackbar" :timeout="5000" bottom>
    {{ snackbarText }}
    <!-- <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Schließen</v-btn>
    </template> -->
  </v-snackbar>
</template>
